<template>

<div class="card">

	<div class="card-header">
		<h3 class="card-title">
			PRIU 福建省模式识别与图像理解重点实验室
		</h3>
	</div>
	<div class="card-body">
		PRIU 福建省模式识别与图像理解重点实验室
	</div>

  </div>
</template>

<script>

export default {
  name: 'Main',
  data () {
    return {
      msg: 'Main'
    }
  },
  components: {
  }

}
</script>

<style scoped>

</style>

